input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #e0e1f3 inset;
    transition: background-color 5000s ease-in-out 0s;
}
.MuiOutlinedInput-root {
    overflow: hidden;
}
input:-webkit-autofill + .MuiInputAdornment-root:before,
input:-webkit-autofill:hover + .MuiInputAdornment-root:before,
input:-webkit-autofill:focus + .MuiInputAdornment-root:before,
textarea:-webkit-autofill + .MuiInputAdornment-root:before,
textarea:-webkit-autofill:hover + .MuiInputAdornment-root:before,
textarea:-webkit-autofill:focus + .MuiInputAdornment-root:before,
select:-webkit-autofill + .MuiInputAdornment-root:before,
select:-webkit-autofill:hover + .MuiInputAdornment-root:before,
select:-webkit-autofill:focus + .MuiInputAdornment-root:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #e0e1f3;
    margin-left: -8px;
}

::selection {
    background-color: #4851f9;
    color: white;
}

::-moz-selection {
    background-color: #4851f9;
    color: white;
}

::-webkit-scrollbar {
    width: 13px;
    height: 13px;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-width: 0 2px 0 0;
    min-height: 20px;
    border: solid transparent;
    border-radius: 6px;
    background-clip: content-box;
    box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.4);
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.pswp__counter {
    float: left;
    position: relative !important;
}

.MuiButtonBase-root.pswp__button--add, .MuiButtonBase-root.pswp__button--delete {
    width: auto;
    background: none;
    color: white;
    float: left;
    display: flex;
}

.menuBackdrop{
    background: transparent;
    backdrop-filter: none;
}
